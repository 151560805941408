<script setup>
const { logout, user } = useSanctumAuth()
</script>

<template>
	<VBadge
		dot
		location="bottom right"
		offset-x="3"
		offset-y="3"
		bordered
		color="success"
		v-if="user?.data">
		<VAvatar class="cursor-pointer" color="primary" variant="tonal">
			{{ user.data.name[0] }}

			<VMenu activator="parent" width="230" location="bottom end" offset="14px">
				<VList>
					<VListItem>
						<template #prepend>
							<VListItemAction start>
								<VBadge
									dot
									location="bottom right"
									offset-x="3"
									offset-y="3"
									color="success">
									<VAvatar color="primary" variant="tonal">
										{{ user.data.name[0] }}
									</VAvatar>
								</VBadge>
							</VListItemAction>
						</template>

						<VListItemTitle class="font-weight-semibold">
							{{ user.data.name }}
						</VListItemTitle>

						<VListItemSubtitle>
							{{ user.data.role }}
						</VListItemSubtitle>
					</VListItem>

					<VListItem link to="profile">
						<template #prepend>
							<VIcon class="me-2" icon="tabler-user" size="22" />
						</template>

						<VListItemTitle>
							{{ $t('profile.profile') }}
						</VListItemTitle>
					</VListItem>

					<VListItem link @click="logout">
						<template #prepend>
							<VIcon class="me-2" icon="tabler-logout" size="22" />
						</template>

						<VListItemTitle>
							{{ $t('profile.logout') }}
						</VListItemTitle>
					</VListItem>
				</VList>
			</VMenu>
		</VAvatar>
	</VBadge>
</template>
